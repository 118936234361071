import React, {forwardRef, useImperativeHandle, useState} from 'react'
import styles from '../styles/promptButton.module.css'

const PromptButton = forwardRef(({children, style, primary, dark, color, danger, prompt, disabled, handleClick, handleConfirm:handleConfirmCallback = () => {}}, ref) => {

    const [confirm, setConfirm] = useState(false)
    const [fetch, setFetch] = useState(false)

    const fetching = (value) => {
        setFetch(value)
    }

    const reset = () => {
        setConfirm(false)
    }

    useImperativeHandle(ref, () => ({
        fetching,
        reset
    }))

    const handleConfirm = () => {
        setConfirm(true)
        if(handleConfirmCallback) {
            handleConfirmCallback()
        }
    }

    const handleYes = e => {
        handleClick(e)
    }

    const handleNo = () => {
        reset()
        if(handleConfirmCallback) {
            handleConfirmCallback()
        }
    }
  
    return (
        <div className={`${styles.container} ${fetch? styles.fetch : ''} ${confirm? styles.confirm : ''}`} style={style} >
            <button className={`${styles.main} ${styles.button} ${primary? styles.primary : ''} ${dark? styles.dark : ''} ${danger? styles.danger : ''} ${styles.spinner} ${fetch && prompt === undefined? styles.fetch : ''}`} onClick={()=> {
                if(prompt !== undefined) {
                    handleConfirm()
                } else {
                    handleClick()
                }
            }} style={{color:(!primary && color !== null? color : null), backgroundColor:(primary && color !== null? color : null)}} disabled={disabled}>{children}</button>
            <span className={`${styles.prompt} ${danger? styles.danger : ''}`}>{prompt}</span>
            <button className={`${styles.button} ${styles.primary} ${danger? styles.danger : ''} ${styles.spinner} ${fetch && prompt !== undefined? styles.fetch : ''}`} onClick={handleYes}>Si</button>
            <button className={styles.button} onClick={handleNo}>No</button>
        </div>
    )
})

export default PromptButton