import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
import styles from '../styles/header.module.css'
import RadX from '../assets/radx-diagnostic-portal.svg'
import MenuIcon from '../assets/menu.svg'
import CloseIcon from '../assets/close.svg'
import IconOurNetwork from "../assets/icon-our-network.svg"

const Header = ({index, scrolled, className}) => {

  const [menu, setMenu] = useState(false)
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    if(menu) {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scroll}px`
    } else {
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, scroll)
    }
  }, [scroll, menu])

  return (
    <header className={`${className} ${styles.header} ${index? styles.transparent : ''} ${menu? styles.open : ''} ${scrolled? styles.scrolled : ''}`}>
      <div className={styles.logo}>
        <Link to={`/`}><RadX/></Link>
      </div>
      <div className={styles.nav} onClick={() => setMenu(false)} role='button' tabIndex='0' onKeyDown={() => {}}>
        <CloseIcon className={styles.closeIcon}/>
        <div className={styles.sections}>
          <Link to={`/`} partiallyActive={false} activeClassName={styles.active}>Home</Link>
          <Link to={`/stock`} partiallyActive={true} activeClassName={styles.active}>Stock</Link>
          <Link to={`/weatherReport`} partiallyActive={true} activeClassName={styles.active}>Weather report</Link>
          <Link to={`/network`} partiallyActive={true} activeClassName={styles.active}>Network</Link>
          <Link to={'/project'} partiallyActive={true}  activeClassName={styles.active}>Awardees</Link>
        </div>
      </div>
      <MenuIcon className={styles.menuIcon} onClick={() => { setMenu(true); setScroll(window.scrollY)}}/>
    </header>
  )
}

export default Header
