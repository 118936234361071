import React from 'react'
import styles from '../styles/input.module.css'

const Input = ({ mandatory = false, error, autoFocus = false, className, type, placeholder, id, name, value, disabled = false, onChange:onChangeCallback, ...other }) => {

	const onChange = (value, checked) => {
		onChangeCallback({
			target: {
				name,
				value,
				checked,
				...other
			}
		})
	}

    return (
		<>	
			<div data-error={error} className={`${error? styles.error : ''} ${styles.input} ${disabled? styles.disabled : ''}`}>
				<input placeholder={placeholder} type={type} name={name} value={value} onChange={e => onChange(e.target.value, e.target.checked, e.target)} disabled={disabled} {...other}/>
			</div>
			{
				error && (
					<div className={styles.errorMessage}>{error}</div>
				)
			}
		</>
	)
}

export default Input 