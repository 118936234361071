import React, {useState, useEffect} from 'react'
import { EMPTY_FIELD, INVALID_PASSWORD } from '../helpers/errors'
import AccessContext from '../context/accessProvider'
import Tooltip from './tooltip'
import Header from './header'
import PromptButton from '../components/promptButton'
import Input from '../components/input'
import SEO from './seo'
import Footer from './footer'
import styles from '../styles/layout.module.css'
import { Helmet } from 'react-helmet'

const Layout = ({ index, children }) => {

  const [scrolled, setScrolled] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState(false)
  const [fetching, setFetching] = useState(false)
  let submitButtonRef

  const handleChange = e => {
    form[e.target.name] = e.target.value
    setForm({...form})
  }

  const handleSubmit = (callback) => {
    console.log(callback)
    const hasErrors = !form.password?.length
    setErrors(hasErrors)
    if(!hasErrors) {
        setFetching(true)
        fetch('/.netlify/functions/signIn', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({
                ...form
            })
        })
        .then(response => response.json())
        .then(data => {
          const { user } = data
          setFetching(false)
          if(user.access) {
            setErrors(false)
            callback(true)
          } else {
            setErrors(true)
          }
        })
    }
}

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 0)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if(submitButtonRef) {
      submitButtonRef.fetching(fetching)
    }
  }, [fetching, submitButtonRef])

  return (
//     <AccessContext.Consumer>
//       {
//         context => context.access? (
          <>
            <SEO/>
            <Helmet>
              <meta name='viewport' content='width=device-width, initial-scale=1'/>
            </Helmet>
            <div className={styles.layout}>
              <Header index={index} scrolled={scrolled} className={styles.header}/>
              <main style={index? {padding: 0} : null}>{children}</main>
              <Footer/>
            </div>
            <Tooltip/>
          </>
      //  )
//          : (
//           <div className={styles.form}>
//             <span>Sign in</span>
//             <label>Password
//               { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
//               <Input type={'password'} name={'password'} value={form.password ?? ''} disabled={fetching} error={errors && !form.password?.length? EMPTY_FIELD : errors && form.password?.length? INVALID_PASSWORD : undefined} onChange={handleChange}/>
//               <PromptButton ref={ref => submitButtonRef = ref} primary={true} handleClick={() => handleSubmit(context.setAccess)}>Sign in</PromptButton>
//             </label>
//           </div>
//         )
//       }
//     </AccessContext.Consumer>
  )
}

export default Layout
