import React, { useEffect } from 'react'
import styles from '../styles/tooltip.module.css'

const Tooltip = () => {

    let tooltipRef, timer

    useEffect(() => {
        document.addEventListener('mouseover', handleMouseOver)
        document.addEventListener('mouseout', handleMouseOut)
        return () => {
            document.removeEventListener('mouseover', handleMouseOver)
            document.removeEventListener('mouseout', handleMouseOut)
        }
    })

    const handleMouseOver = e => {
        if(e.target.getAttribute('data-tooltip') && (!e.target.getAttribute('data-tooltip-only-if-overflow') || e.target.scrollWidth - e.target.clientWidth > 0 || e.target.scrollHeight - e.target.clientHeight > 0)) {
            tooltipRef.textContent = e.target.getAttribute('data-tooltip')
            timer = setTimeout(() => {
                const offset = 6
                const targetBounds = e.target.getBoundingClientRect()
                const tooltipBounds = tooltipRef.getBoundingClientRect()
                const x = Math.max(offset, Math.min(window.innerWidth - tooltipBounds.width - offset, targetBounds.left + targetBounds.width / 2 - tooltipBounds.width / 2)) + window.scrollX
                let y = targetBounds.top - tooltipBounds.height - offset + window.scrollY
                if (y < window.scrollY) {
                    y = targetBounds.bottom + offset + window.scrollY
                }
                tooltipRef.style.left = `${x}px`
                tooltipRef.style.top = `${y}px`
                tooltipRef.classList.add(styles.visible)
            }, 500)            
        }
    }

    const handleMouseOut = e => {
        if(e.target.getAttribute('data-tooltip')) {
            tooltipRef.classList.remove(styles.visible)
            clearTimeout(timer)
        }
    }

    return <div className={styles.tooltip} ref={ref => tooltipRef = ref} style={{top: 0, left: 0}}/>
}

export default Tooltip
