import React from 'react'
import { Link } from 'gatsby'
import styles from '../styles/footer.module.css'
import RadX from '../assets/radx-diagnostic-portal.svg'
import CovidCalc from '../assets/covid-calc.svg'

const Footer = () => {

	return (
		<footer className={styles.footer}>
			<div className={styles.row}>
				<div className={styles.logo}>
					<Link to={`/`}><RadX/></Link>
				</div>
			</div>
			<div className={styles.copyright}>The RADx-rad DCC is funded by NIH (U24LM01375)</div>
				<div className={styles.contactus}>Questions? Contact us at <a href='mailto:radx-rad-admin@ucsd.edu'>radx-rad-admin@ucsd.edu</a></div>
				<div className={styles.channels}>
				<a href={'https://covid-calc.herokuapp.com/'} target='_blank' rel='noreferrer'>
					<CovidCalc/>
				</a>
			</div>
		</footer>
	)
}

export default Footer
